import rozanac from "../temp_assets/rozanac.mp4";
import svpetra from "../temp_assets/svpetra.mp4";
import bliznice from "../temp_assets/bliznice.mp4";
import postinje from "../temp_assets/postinje.mp4";
import mihovil from "../temp_assets/mihovil.mp4";
import dobrec from "../temp_assets/dobrec.mp4";
import ormen from "../temp_assets/ormen.mp4";
import kapelica from "../temp_assets/kapelica.mp4";
import svilija from "../temp_assets/svilija.mp4";
import krizi from "../temp_assets/krizi.mp4";

export const locations = [
  {
    "id": 1,
    "Name": "GROBLJE ŠOLIĆA BRIG U SUTINI",
    "NameEN": "THE CEMETERY ŠOLIĆA BRIG IN SUTINA",
    "DescriptionEN": "On the western slopes of the hill Šolića Brig there is a massive stone cross and a memorial plaque that marks a Post-Medieval cemetery (16th -18th century) where, just like the cemeteries in Križi, Neorić, Gizdavac and Kerumi, the population was buried during the Ottoman rule. Cemeteries of this period are known for stećci, massive tombstones most frequently square or in the shape of a cross.",
    "coordinates": [
      16.529156416878,
      43.6940079662718
    ],
    "Description": "Na zapadnoj strani brda izdiže se masivan kameni križ i spomen ploča koja označava novovjekovno groblje (16.-18. st.) na kojem se, kao i na grobljima u Križima, Neoriću, Gizdavcu i Kerumima, populacija pokapala u vrijeme turske vladavine. Groblja ovog perioda odlikuju se stećcima, masivnim kamenim spomenicima najčešće četvrtaste forme i u obliku križa..",
    "assets": [
    ]
  },
  {
    "id": 2,
    "Name": "CRKVA SV. ILIJE U SUTINI",
    "NameEN": "THE CHURCH OF ST. ELIJAH IN SUTINA",
    "DescriptionEN": "The church of St. Elijah was restored in the early 20th century, and is probably situated on top of an even earlier church. In its courtyard and the surrounding courtyard wall there are two Roman funerary stelae, which suggests that the church was built on the site of a Roman cemetery. Since Roman cemeteries are always situated near roads, these monuments confirm that there was a Roman road in the vicinity which lead towards the Sutina river canyon from where it forked in two directions, one leading toward the city of Aequum (present-day village of Čitluk near Karakašica) in the north and the other toward the town Osinium (present-day city of Sinj) in the east.",
    "coordinates": [
      16.5206145476116,
      43.6921250496493
    ],
    "Description": "Crkva Sv. Ilije je restaurirana početkom 20. st., a vjerojatno se nalazi na mjestu još starije crkve. U dvorištu i ogradnom zidu sadrži dvije rimske pogrebne stele, nadgrobna spomenika, što ukazuje da je građena na rimskom groblju. Kako se rimska groblja uvijek nalaze uz rimske ceste, stele potvrđuju da je ovim putem prolazila rimska cesta prema kanjonu Sutine odakle se račvala prema rimskom naselju Aequum (današnji Čitluk kod Karakašice) na sjever te prema Osiniju (današnji Sinj) na istok.",
    "assets": [
svilija
    ]
  },
  {
    "id": 3,
    "Name": "BANOVA KULA U NEORIĆU",
    "NameEN": "BAN'S (VICE-ROY'S) TOWER IN NEORIĆ",
    "DescriptionEN": "A hill named Kula holds the remains of a large circular defensive tower which was a part of a medieval town Ne(h)orić, known from historical sources as the seat of the noble family and the ban (Vice-Roy) Ne(h)orić. Only the foundation layer now remains of the tower. Here and there on the slopes of the hill remains of the city wall and smaller rectangular towers can be discerned. It is not known when the town was constructed, but the historical sources tell us that it was conquered by the Ottoman army in the year 1538; a year after the famous fortress of Klis also fell into their hands.",
    "coordinates": [
      16.5329636103121,
      43.6812602294898
    ],
    "Description": "Na brdu Kula su ostaci velike kružne branič-kule srednjovjekovnoga grada Ne(h)orića koje se u povijesnim izvorima spominje kao sijelo bana Ne(h)orića. Sačuvana je samo u temeljnoj stopi.. Na obroncima se mjestimice razaznaju ostatci bedema i manjih četvrtastih kula. Vrijeme gradnje je nepoznato, a prema povijesnim izvorima, Turci su ga osvojili 1538. godine, godinu dana nakon znamenite utvrde u Klisu.",
    "assets": [
    ]
  },
  {
    "id": 4,
    "Name": "ARHEOLOŠKI LOKALITET DOBREČ, RIMSKO NASELJE ANDETRIUM",
  "NameEN": "ARCHAEOLOGICAL SITE DOBREČ, THE ROMAN TOWN OF ANDETRIUM",
"DescriptionEN": "The Roman town Andetrium, shown on a map of the Roman Empire (Tabula Peutingeriana), was situated near the crossroads of two Roman roads and was occupied from the Early to the Late Roman Period (1st to 7th century AD). Archaeological excavations have uncovered the remains of walls of spacious buildings with porches supported by columns, walls decorated with frescoes, mosaic floors, drainage channels and a plethora of small finds including tools, jewellery and items used for board games.",
    "coordinates": [
      16.5090138896031,
      43.6897410270157
    ],
    "Description": "Rimsko naselje Andetrium, prikazano i na karti Rimskog Carstva (Tabula Peutingeriana), smješteno je blizu raskrižja dviju rimskih cesta, a građeno nastanjeno je od razdoblja Ranog Carstva do kasne antike (1.-7. st.). Istraživanja su otkrila ostatke zidova velikih građevina s trjemovima poduprtim stupovima, zidove ukrašene freskama, podove s mozaicima, kanale za odvodnju i mnoštvo svakodnevnih sitnih predmeta - alata, nakita, pribora za igre na ploči.",
    "assets": [
dobrec
    ]
  },
  // {
  //   "id": 5,
  //   "Name": "gomila na Velikoj Glavici",
  //   "coordinates": [
  //     16.5019883434598,
  //     43.6761961847646
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 6,
  //   "Name": "gomila na Velikoj Glavici",
  //   "coordinates": [
  //     16.5019883434598,
  //     43.6761961847646
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 7,
  //   "Name": "gomila na Velikoj Glavici",
  //   "coordinates": [
  //     16.5019883434598,
  //     43.6761961847646
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 8,
  //   "Name": "gomila na Maloj Glavici",
  //   "coordinates": [
  //     16.5064050832364,
  //     43.6768029818435
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 9,
  //   "Name": "Kraljevac (Bakića bunar)",
  //   "coordinates": [
  //     16.470686935499,
  //     43.6559666072996
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 10,
  //   "Name": "Bašića bunari",
  //   "coordinates": [
  //     16.4708047024156,
  //     43.6432945505096
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 11,
  //   "Name": "Bunari Trnovača",
  //   "coordinates": [
  //     16.484319808648,
  //     43.64874381311031
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 36,
  //   "Name": "Mala Pećina",
  //   "coordinates": [
  //     16.5475821853335,
  //     43.6883367049529
  //   ]
  // },
  {
    "id": 12,
    "Name": "1.	CRKVA SV. PETRA U GORNJEMU MUĆU",
  "NameEN": "THE CHURCH OF ST. PETER IN GORNJI MUĆ",
    "coordinates": [
      16.4844270636908,
      43.6911827940987
    ],
    "Description": "Natpis na kamenom ulomku oltarne pregrade, koja se čuva u Arheološkome muzeju u Zagrebu, navodi da je hrvatski knez Branimir na ovome mjestu dao podići crkvu 888. godine.. Mjesto i pripadajuća župa zvalo se Zmina/Smyna. Ime je zadržano do kasnog srednjeg vijeka kad se prvi puta spominje mjesto Muć (Mucchio). Južno od crkve pronađeni su ostaci još starije, rimske građevine, najvjerojatnije ville rustice.",
    "DescriptionEN": "Inscription found on a fragment of an altar partition, currently kept in the Archaeological Museum in Zagreb, states that the Croatian prince (dux) Branimir had a church built in Gornji Muć in the year 888 AD. The town and the associated parish were called Zmina/Smyna. This name was used until the Late Middle Ages when the name Muć (Mucchio) was first mentioned. South of the church remains of an even older, Roman building, most likely a villa rustica, were found. ",
    "assets": [
svpetra
    ]
  },
  {
    "id": 13,
    "Name": "CRKVA SV. MIHOVILA U GRANIĆIMA (KMETI) U DONJEMU MUĆU",
    "NameEN": "THE CRUCH OF ST. MICHAEL IN GRANIĆI (KMETI) IN DONJI MUĆ",
  "DescriptionEN": "The church of St. Michael was built by the Italian noble family Cambi at the beginning of the 18th century, at the time when they became lords of this part of the Field of Muć. In addition to the church, they built a manor house and a tower in the Field of Muć, which have not been preserved. The church was built on top of an earlier church from the 15th century which had been demolished, and was constructed using some of the medieval masonry, which is visible today in its gothic style windows. The church has been completely restored and since 2019 service is held here every year on the day of the patron saint, September 29. ",
    "coordinates": [
      16.43917061172698,
      43.69211116810338
    ],
    "Description": "Crkvu Sv. Mihovila dala je sagraditi talijanska plemićka obitelj Cambi početkom 18. st. u vrijeme kad su zagospodarili dijelom Mućkog polja. Osim crkve sagradili su i kuriju - palaču Cambi i kulu u Mućkom polju koje nisu sačuvane. Crkva je građena na temelju starije porušene crkve s početka 15. st. i djelomično istim građevinskim materijalom na što ukazuju gotički prozori. U potpunosti je restaurirana te se u njoj od 2019. godine služi Euharistija na dan sveca zaštitnika, Sv. Mihovila, 29. rujna.",
    "assets": [
      mihovil
    ]
  },
  // {
  //   "id": 14,
  //   "Name": "gradina Križina",
  //   "coordinates": [
  //     16.43391192124114,
  //     43.69232018711211
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 15,
  //   "Name": "gradina u Neoriću",
  //   "coordinates": [
  //     16.11221111402779,
  //     43.69006761121111
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 16,
  //   "Name": "Burića gradina",
  //   "coordinates": [
  //     16.449411116221125,
  //     43.70947676946612
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 17,
  //   "Name": "Gradina u Tešijama",
  //   "coordinates": [
  //     16.43111808131177,
  //     43.73186881126282
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 18,
  //   "Name": "Nekropola u Kerumima",
  //   "coordinates": [
  //     16.43927082811949,
  //     43.7262321341111
  //   ],
  //   "Description": ""
  // },
  {
    "id": 19,
    "Name": "GRADINA U POSTINJU",
    "NameEN": "THE HILLFORT IN POSTINJE",
  "DescriptionEN": "The fortified settlement above the village of Postinje is the largest hillfort in the Muć Municipality. Its vast plateau is situated on the top of an almost vertical cliff. This easily defendable position is not surrounded by a wall but has the settlement plateau separated from the rest of the hill by three large parallel walls which also contain burials. Sherds of Bronze Age and Iron Age pottery have been found on the plateau. It has recently been suggested that this hillfort could be the location of the prehistoric town of Andetrium.",
    "coordinates": [
      16.402797746619,
      43.7136473809829
    ],
    "Description": "Utvrđeno naselje iznad Postinja je najveći gradinski objekt na prostoru Općine Muć. Lako branjiv položaj nije okružen bedemom, već je plato odsječen od ostatka brda trima velikim poprečnim bedemima u kojima su i grobne konstrukcije. Na platou su pronađeni ulomci keramike iz brončanog i željeznog doba. U novije vrijeme se Gradina navodi kao moguća lokacija prapovijesnog Andetrija.",
    "assets": [
      postinje
    ]
  },
  // {
  //   "id": 20,
  //   "Name": "Gradina Ramljane",
  //   "coordinates": [
  //     16.37848274684912,
  //     43.72871180024431
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 21,
  //   "Name": "Jelačića Glavica",
  //   "coordinates": [
  //     16.38781155506332,
  //     43.7243240689749
  //   ],
  //   "Description": ""
  // },
  {
    "id": 23,
    "Name": "KAPELICA",
"NameEN": "KAPELICA",
"DescriptionEN": "The site Kapelica contains the preserved and restored remains of an Early Medieval small church with a surrounding cemetery (dated from the 9th to the 14th century) and a medieval fortress which is considered to be the fortress of Brečevo, known in the historical sources as the seat of the Croatian noble family Nelipić. A large quantity of Roman finds suggests that this was also the location of a Late Roman fortress. About 400 m to the west there is a necropolis called Križi (Crosses) named for two massive stone crosses which marked the positions of the Late Medieval and Post-Medieval (16th to 18th century) graves. Under the cairns situated on the same site there is a Roman sarcophagus and Late Roman (4th – 5th century) walled tombs.",
    "coordinates": [
      16.3871561959886,
      43.7353502343776
    ],
    "Description": "Lokalitet Kapelica sadrži sačuvane i konzervirane ostatke srednjovjekovne crkvice i okolnih grobova (9. - 14. st.) te srednjovjekovne utvrde za koju se pretpostavlja da je utvrda Brečevo, poznata iz povijesnih izvora kao sijelo knezova Nelipića. Veća količina rimskih nalaza ukazuju da je na tom mjestu bila kasnoantička utvrda. Oko 400 m zapadno od Kapelice nalazi se nekropola Križi nazvana po ostacima dvaju masivnih kamenih križeva s grobova kasnog srednjeg i ranog novog vijeka (16.-18. st.). Pod gomilama se nalaze i antički sarkofag te zidane grobnice iz razdoblja kasne antike (4.- 5. st.).",
    "assets": [
      kapelica
    ]
  },
  {
    "id": 24,
    "Name": "KRIŽI",
  "NameEN": "KRIŽI",
  "DescriptionEN": "The site Kapelica contains the preserved and restored remains of an Early Medieval small church with a surrounding cemetery (dated from the 9th to the 14th century) and a medieval fortress which is considered to be the fortress of Brečevo, known in the historical sources as the seat of the Croatian noble family Nelipić. A large quantity of Roman finds suggests that this was also the location of a Late Roman fortress. About 400 m to the west there is a necropolis called Križi (Crosses) named for two massive stone crosses which marked the positions of the Late Medieval and Post-Medieval (16th to 18th century) graves. Under the cairns situated on the same site there is a Roman sarcophagus and Late Roman (4th – 5th century) walled tombs.",
    "coordinates": [
      16.3858725991612,
      43.7381966819985
    ],
    "Description": "Lokalitet Kapelica sadrži sačuvane i konzervirane ostatke srednjovjekovne crkvice i okolnih grobova (9. - 14. st.) te srednjovjekovne utvrde za koju se pretpostavlja da je utvrda Brečevo, poznata iz povijesnih izvora kao sijelo knezova Nelipića. Veća količina rimskih nalaza ukazuju da je na tom mjestu bila kasnoantička utvrda. Oko 400 m zapadno od Kapelice nalazi se nekropola Križi nazvana po ostacima dvaju masivnih kamenih križeva s grobova kasnog srednjeg i ranog novog vijeka (16.-18. st.). Pod gomilama se nalaze i antički sarkofag te zidane grobnice iz razdoblja kasne antike (4.- 5. st.). ",
    "assets": [
      krizi
    ]
  },
  // {
  //   "id": 25,
  //   "Name": "Lokalitet Dževerdžine",
  //   "coordinates": [
  //     16.384994709619,
  //     43.7332844417639
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 26,
  //   "Name": "Lokalitet Kvartiri",
  //   "coordinates": [
  //     16.3779065764886,
  //     43.7354199747974
  //   ],
  //   "Description": ""
  // },
  {
    "id": 27,
    "Name": "GRADINA ORMEN",
    "NameEN": "THE HILLFORT ORMEN",
    "DescriptionEN": "The fortified settlement Ormen is of an irregular, almost triangular shape with rounded points. It lies on two levels separated by a wall, while the entrance is assumed to be on the west side. The burial ground for the population that lived here was probably situated east of the hillfort on the site called Krči in the village Duke in Bračević.",    
    "coordinates": [
      16.3790599641425,
      43.747316882355
    ],
    "Description": "Utvrđeno naselje Ormen je nepravilnog, gotovo trokutastog tlocrta sa zaobljenim vrhovima. Položen je na dvije razine odijeljene bedemom, a pretpostavljeni ulaz je na zapadnoj strani. Nekropola stanovnika Ormena vjerojatno se nalazi na istoku, na lokalitetu Krči u Dukama (Bračević).",
    "assets": [
      ormen
    ]
  },
  // {
  //   "id": 28,
  //   "Name": "gomile iznad Crivca",
  //   "coordinates": [
  //     16.3622356204237,
  //     43.7583649493257
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 29,
  //   "Name": "Rotna (Rotina) gomila",
  //   "coordinates": [
  //     16.3431967045477,
  //     43.7678148343336
  //   ],
  //   "Description": ""
  // },
  {
    "id": 30,
    "Name": "TURSKI BUNARI (BLIZNICE) U PRIBUDAMA",
    "NameEN": "TURKISH (OTTOMAN) WELLS BLIZNICE IN PRIBUDE",
    "DescriptionEN": "In the mountainous region of pasture land with numerous dry-wall enclosures used for grazing and keeping cattle there are 9 wells of circular form constructed in the dry-wall technique, as well as stone and concrete troughs for pouring in water. The site lacks finds which would confirm with certainty that the wells were used during the Ottoman rule.",
    "coordinates": [
      16.3956548938074,
      43.7653119468647
    ],
    "Description": "Na području planinskih pašnjaka s brojnim suhozidnim ogradama za čuvanje i ispašu stoke nalazi se 9 bunara kružnog tlocrta kamenih rubova složenih u suhozidnoj tehnici i kamena i betonska korita u koja se mogla izliti voda. Nema nalaza koji bi sa sigurnošću potvrdili da su se koristili u vrijeme turske prevlasti.",
    "assets": [
      bliznice
    ]
  },
  {
    "id": 31,
    "Name": "GRADINA ROŽANAC",
    "NameEN": "THE HILLFORT ROŽANAC",
    "DescriptionEN": "The fortified settlement Rožanac is a small hill with a flat top that also contains two circular walls built in the dry-wall technique, one near the top of the hill, the other on a lower slope. It is undoubtedly the site of a prehistoric hillfort, which is confirmed by the presence of large funerary mounds, cairns, located at the foot of the hill.",
    "coordinates": [
      16.3906264208902,
      43.780801571346
    ],
    "Description": "Utvrđeno naselje Rožanac je manje brdo zaravnjena vrha s dva kružna koncentirčna suhozidna bedema, jedan oko vršnog platoa, jedan niže. Vrlo je izvjesno da se ovdje radi o gradinskom lokalitetu, što potvrđuju i grobni humci, kamene gomile većih dimenzija u njegovom podnožju.",
    "assets": [
      rozanac
    ]
  },
  // {
  //   "id": 32,
  //   "Name": "gomila Čambrovica",
  //   "coordinates": [
  //     16.4493964408459,
  //     43.7617197929977
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 33,
  //   "Name": "Crkva Sv. Jurja",
  //   "coordinates": [
  //     16.4543505191602,
  //     43.7360786154941
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 34,
  //   "Name": "Gradina Ćukova Greda",
  //   "coordinates": [
  //     16.4946511877796,
  //     43.7041598056918
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 35,
  //   "Name": "gradina kod crkve Sv. Ane",
  //   "coordinates": [
  //     16.5204186006731,
  //     43.7297171594225
  //   ],
  //   "Description": ""
  // },
  // {
  //   "id": 36,
  //   "Name": "Mala Pećina",
  //   "coordinates": [
  //     16.5475821853335,
  //     43.6883367049529
  //   ],
  //   "Description": ""
  // }
]