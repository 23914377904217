import React, { useContext } from "react";
import Map from "../components/map/map";
import { EN, HR, LangContext } from "../contexts/lang";

const Home = (props: any) => {
  const [initialLoad, setInitialLoad] = React.useState(true);
  const { lang, setLang } = useContext(LangContext);
  return (
    <>
    {initialLoad ? (
      <>
      <div className="initial-modal">
        <div className="initial-modal-content">
          <h1>Arheološka mapa Općine Muć</h1>
      <p>Povijest Općine Muć seže u vrijeme neolitika iz kojeg datiraju najstariji arheološki nalazi, fragmenti keramike iz Male pećine nastali oko 4.700 g. p.n.e.  Svjedoci od tada kontinuiranog prisustva čovjeka su ostaci prapovijesnih gradina (utvrđenih naselja) i gomila (kamenih grobnih humaka) koji se vezuju uz predrimsko ilirsko pleme Delmate.  Vladavinu Rima od 9. g.n.e. potvrđuju ostaci antičkih cesta, vojnih logora i naselja, grobova i komunalne infrastrukture. Ostaci crkava, utvrda i civilnih građevina govore o burnom životu u vremenu srednjeg vijeka, dok je u novom vijeku francuska vlast obnovila antičku prometnicu, a razdoblje austrijske vlasti donijelo je obnovu sakralnih objekata, konsolidaciju lokalne uprave te gradnju škola.</p>
      <div className="buttons">
          <button onClick={() => { setLang(HR); setInitialLoad(false)}}>Nastavak</button>
      </div>
      <h1>Archaeological map of Muć Municipality</h1>
      <p>History of the Muć Municipality reaches all the way to the Neolithic period, about 4700 BC, to which the earliest finds, pottery sherds from the Mala Pećina cave, are dated. Remains of prehistoric hillforts (fortified settlements) and cairns (burial mounds made of rock) bear witness to the continuous occupation of the region and are associated with the pre-Roman Illyrian tribe called the Delmatae.  Roman rule in the area begins at 9 AD and is confirmed by the remains of roads, military camps and civilian settlements, cemeteries and infrastructure. The remains of churches, forts and civilian structures indicate the tumultuous life in the Middle Ages, while in the Post-Medieval period the French government renovated and reused a Roman road, and the period of Austrian rule brought about the restoration of religious structures, consolidation of local government and the construction of schools.</p>
      <div className="buttons">
          <button onClick={() => { setLang(EN); setInitialLoad(false)}}>Continue</button>
      </div>
          </div>
      </div>
      <Map />
      </>
    ) : (
      <Map />
    )}
    </>
  );
};

export default Home;

