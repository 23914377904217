import "normalize.css";
import React from "react";
import Home from "./pages/Home";
import { LangProvider } from "./contexts/lang";

const App = (): JSX.Element => {
  return (
    <div className="root">
      <LangProvider>
        <Home />
      </LangProvider>
    </div>
  );
};

export default App;
