import React, { useContext } from "react";
import { LangContext } from "../../contexts/lang";
import dictionary from "../../dict";
import { mapIdToMarkerIcon } from "../../utils/mapIdToIcon";
import "./style.scss";

const LegendHeading = ({ categoryName, absolute = true }:
                         {
                           categoryName: string;
                           absolute?: boolean;
                         }) => {
  const { lang } = useContext(LangContext);

  return (
    <div className={`img-type ${absolute ? "absolute" : ""}`}>
      <span>{categoryName}</span>
    </div>
  );
};

export default LegendHeading;
