import React, { useState } from "react";
import { IContextProps } from "./types";

export const HR = "HR";
export const EN = "EN";
const langObj = {
  lang: HR,
  // tslint:disable-next-line:no-empty
  setLang: (arg: string) => {},
};
export const LangContext = React.createContext(langObj);

export const LangProvider = ({ children }: IContextProps) => {
  const [lang, setLang] = useState(HR);
  return (
    <LangContext.Provider value={{ lang, setLang }}>
      {children}
    </LangContext.Provider>
  );
};
