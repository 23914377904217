import React, { useContext, useEffect, useState } from "react";
import ModalLarge from "../../components/modal/modal-large";
import { mapIdToMarkerIcon } from "../../utils/mapIdToIcon";
import { mapStyles } from "./mapStyles";
import { locations } from "../../constants/locations";
import "./style.scss";
import { getModalData } from "./getModalData";
import { EN, LangContext } from "../../contexts/lang";

const apiUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC749mCezlo2IriQ-Lr26pwVaC1ED8qgKE&libraries=places&output=json&callback=initMap`;

let markerListeners: any[] = [];
let markers: any[] = [];

const Map = () => {
    const [modal, setModal] = useState<any>(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [mapLocations, setMapLocations] = useState<any>([]);
    const [currentLocation, setCurrentLocation] = useState({
      lat: 43.7230,
      lng: 16.45
    });
    const { lang } = useContext(LangContext);

    const [zoomLevel] = useState(13);
    const exitModal = () => setModal(null);

    useEffect(() => {
      // @ts-ignore
      if (mapLoaded && typeof map.panTo === "function") {
        if (typeof google === "undefined") {
          return setMapLoaded(false);
        }
        const { lat, lng } = currentLocation;
        const myLatlng = new google.maps.LatLng(lat, lng);
        // @ts-ignore
        map.panTo(myLatlng);
        // @ts-ignore
        map.setZoom(zoomLevel);
      }
    }, [currentLocation]);

    useEffect(() => {

      window.initMap = () => {
        window.map = new google.maps.Map(
          // @ts-ignore
          document.getElementById(`map`),
          {
            center: currentLocation,
            disableDefaultUI: true,
            styles: mapStyles,
            zoom: zoomLevel,
            draggable: true,
            zoomControl: true,
          },
        );
      };

      if (window.google) {
        window.initMap();
        return setMapLoaded(true);
      }
      const clusterScript = document.createElement("script");
      clusterScript.async = false;
      clusterScript.type = "text/javascript";

      const mapScript = document.createElement("script");
      mapScript.async = false;
      mapScript.type = "text/javascript";
      mapScript.src = apiUrl;
      mapScript.onload = () => setMapLoaded(true);
      document.getElementsByTagName("head")[0].appendChild(mapScript);
    }, []);

    useEffect(() => {
      markers = [];
      setMapLocations(locations);
    }, []);

    useEffect(() => {
      if (!mapLoaded || typeof google === "undefined") {
        return;
      }

      const input: any = document.getElementById(
        "map-search-input",
      );

      let autoCompleteListener: any;
      // tslint:disable-next-line:prefer-const
      let autocomplete: any;

      if (input) {
        const options = {
          componentRestrictions: { country: "hr" },
        };
        // tslint:disable-next-line:no-shadowed-variable
        const autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.setFields(["geometry"]);
        autoCompleteListener = autocomplete.addListener("place_changed", () => {
          const { geometry } = autocomplete.getPlace();
          if (geometry && geometry.location) {
            const lat = geometry.location.lat();
            const lng = geometry.location.lng();
            setCurrentLocation({ lat, lng });
          }
        });
      }
        const allowedBounds = new window.google.maps.LatLngBounds(
          new google.maps.LatLng(43.7230, 16.45),
          new google.maps.LatLng(43.7230, 16.45)
        );
      window.google.maps.event.addListener(window.map, "center_changed", () => { 
      window.map.panToBounds(allowedBounds);
      console.log(1
        );
      })
      markers.forEach((marker) => marker.setMap(null));
      markers = markers.filter((marker) => !!marker.map);

      mapLocations.forEach((location: any) => {
        const lat = location.coordinates[1];
        const lng = location.coordinates[0];
        if (lat && lng) {
          const marker = new google.maps.Marker({
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map: window.map,
          });

          markers.push(marker);

          const handler = (id: string) => () => {

            setModal(getModalData(location.id, locations));
          };

          const listener = marker.addListener("click", handler(location.id));
          markerListeners.push(listener);
        }
      });

      let markerCluster = {
        setMap: (_arg: any) => {
        },
      };

      // @ts-ignore
      if (typeof MarkerClusterer !== "undefined") {
        // @ts-ignore
        markerCluster = new MarkerClusterer(map, markers, {
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        });
      }

      return () => {
        markerListeners.forEach((marker) =>
          google.maps.event.removeListener(marker),
        );
        markerListeners = [];

        if (autocomplete && autoCompleteListener) {
          autocomplete.removeListener(autoCompleteListener);
        }

        markerCluster.setMap(null);
      };
    }, [mapLocations, mapLoaded]);


    return (
      <div onKeyDown={(e) => (e.key === "Escape" ? setModal(null) : "")} className="map-wrapper">
        <div id="map" />
        <div className="arheological-map">
          <h1>{lang === EN ? "Archaeological map of Muć Municipality": "Arheološka mapa Općine Muć"}</h1>
        </div>
        <ModalLarge modal={modal} exitModal={exitModal} />
      </div>
    );
  }
;

export default Map;
