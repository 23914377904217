export const getModalData = (id: string, locations: any) => {
  const location = locations.find((l: any) => l.id === id);

  return {
    name: location.Name,
    description: location.Description,
    nameEN: location.NameEN,
    descriptionEN: location.DescriptionEN,
    asset: location.assets.length > 0 ? location.assets[0] : null,
  };
};
