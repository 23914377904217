import React, { useContext } from "react";
import { EN, LangContext } from "../../../contexts/lang";
import LegendHeading from "../../legend-heading";
import "./style.scss";
interface IModalProps {
  modal: any;
  exitModal: any;
}

const ModalLarge = ({ modal, exitModal }: IModalProps) => {
    const { lang } = useContext(LangContext);

    if (!modal) {
      return null;
    }


    return (
      <div className="modal-large">
        <div className="modal-exit" onClick={exitModal}>
          <div></div>
          <div></div>
        </div>
        <div className="modal-pics">
          <video src={modal.asset} style={{
            width: "100%",
            height: "100%",
          }}
          autoPlay={true}
          loop={true}
          ></video>
        </div>
        <div className="modal-about">
          <LegendHeading categoryName={lang === EN ? modal.nameEN : modal.name} absolute={false} />
          <p>{lang === EN ? modal.descriptionEN : modal.description}</p>
        </div>
      </div>
    );
  }
;

export default ModalLarge;
